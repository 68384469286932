import styles from "./Header.module.css";
import Hero from "./Hero"
import Wave from "./Wave";

const Header = () => {
    return ( 
        <header className={styles.hero}>
            <Hero/>
            <Wave/>
        </header>
     );
}
 
export default Header;