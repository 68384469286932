import nosotros from "./../../assets/equipo.jpg"
import styles from "./Presentation.module.css";
import ButtonS from "./ButtonS";

const Presentation = () => {
    return ( 
        <section className={`${styles.presentation} ${styles.container}`}>
        
          <img src={nosotros} alt="Imagen de nosotros" className={styles.presentation_picture}/>
          
          <h2 className={styles.subtitle}>
              Nosotros somos StockDental
          </h2>
          
          <p className={styles.presentation_copy} >
              Somos un negoció familiar que nació de la idea de ofrecer la mejor calidad y precio en insumos dentales, enfocado principalmente en la distribución al por menor y mayor de productos para estudiantes y profecionales de las clinicas dentales mas reconocidas del pais.
          </p>
          
          <ButtonS/>
        
        </section>
     );
}
 
export default Presentation;