import styles from "./ButtonP.module.css";
import { Link, BrowserRouter as Router } from "react-router-dom";

const ButtonP = () => {
    return ( 
        <Router>
            <Link className={styles.primary} to="#">
                Contactar
            </Link>
        </Router>
     );
}
 
export default ButtonP;