import { Link, BrowserRouter as Router } from "react-router-dom";
import styles from "./Hero.module.css";
import ButtonP from "./ButtonP";

const HeaderComponents = () => {
  return (

      <div className={styles.container}>
        
        <nav className={styles.nav}>
          <Router>
            <Link to="#" className={`${styles.nav_items} ${styles.nav_items_cta}`}>
              Productos
            </Link>
            <Link to="#" className={styles.nav_items}>
              Nosotros
            </Link>
            <Link to="#" className={styles.nav_items}>
              Contacto
            </Link>
          </Router>
        </nav>

        <section className={styles.hero_container}>

          <div className={styles.hero_texts}>
            <h1 className={styles.hero_title}>
              Nuestra Preocupación es la Calidad de tus Productos
            </h1>
            <h2 className={styles.hero_subtitle}>
              Conoce nuestros Insumos Dentales
            </h2>
            <ButtonP/>
            
          </div>
          
        </section>

      </div>
  );
};

export default HeaderComponents;
