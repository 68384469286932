import styles from "./ButtonS.module.css";
import { Link, BrowserRouter as Router } from "react-router-dom";

const ButtonS = () => {
    return ( 
        <Router>
              <Link className={styles.secondary} to="#" >
                  Contactar
                </Link>
          </Router>
     );
}
 
export default ButtonS;