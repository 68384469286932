import { Link, BrowserRouter as Router } from "react-router-dom";
import { FaFacebook } from "react-icons/fa"
import { FaInstagram } from "react-icons/fa"
import { FaWhatsapp } from "react-icons/fa"
import { GrMail } from "react-icons/gr"
import styles from "./Footer.module.css";


const Footer = () => {
    return ( 
        <footer className={styles.footer}>
          <div className={`${styles.container} ${styles.footer_grid}`} >
            
            <nav className={styles.nav}>
              <Router>
                <Link className={styles.nav_items} to="#">Inicio</Link>
                <Link className={styles.nav_items} to="#">Productos</Link>
                <Link className={styles.nav_items} to="#">Nosotros</Link>
                <Link className={styles.nav_items} to="#">Contacto</Link>
              </Router>
            </nav>
            
            <section className={styles.footer_contact}>
              <h3 className={styles.footer_title}>Contactanos</h3>
              <div className={styles.footer_icons}>
                <Router>
                  <Link to="#" className={styles.footer_icon}><GrMail/></Link>
                  <Link to="#" className={styles.footer_icon}><FaFacebook/></Link>
                  <Link to="#" className={styles.footer_icon} ><FaInstagram/></Link>
                  <Link to="#" className={styles.footer_icon}><FaWhatsapp/></Link>
                </Router>
              </div>

            </section>
          </div>
        </footer>
     );
}
 
export default Footer;