import styles from "./Products.module.css";
import { FaFileAlt } from "react-icons/fa"
import img1 from "./../../assets/img1.jpg"
import img2 from "./../../assets/img2.jpg"
import img3 from "./../../assets/img3.jpg"
import img4 from "./../../assets/img4.jpg"
import img5 from "./../../assets/img5.jpg"

const Products = () => {
    return ( 
        <section className={styles.projects}>
          <div className={styles.container}>
            <h2 className={styles.subtitle}>Nuestros Productos</h2>
            <div className={styles.projects_grid}>
              
              <article className={styles.projects_item}>
                <img src={img1} alt="img1" className={styles.projects_img} />
                <div className={styles.projects_hover}>
                  <h2 className={styles.projects_title}>Higiene</h2>
                  <FaFileAlt className={styles.projects_icon}/>
                </div>
              </article>

              <article className={styles.projects_item}>
                <img src={img2} alt="img2" className={styles.projects_img} />
                <div className={styles.projects_hover}>
                  <h2 className={styles.projects_title}>Instrumental</h2>
                  <FaFileAlt className={styles.projects_icon}/>
                </div>
              </article>

              <article className={styles.projects_item}>
                <img src={img3} alt="img3" className={styles.projects_img} />
                <div className={styles.projects_hover}>
                  <h2 className={styles.projects_title}>Utensilios</h2>
                  <FaFileAlt className={styles.projects_icon}/>
                </div>
              </article>

              <article className={styles.projects_item}>
                <img src={img4} alt="img4" className={styles.projects_img} />
                <div className={styles.projects_hover}>
                  <h2 className={styles.projects_title}>Cirugia</h2>
                  <FaFileAlt className={styles.projects_icon}/>
                </div>
              </article>

              <article className={styles.projects_item}>
                <img src={img5} alt="img5" className={styles.projects_img} />
                <div className={styles.projects_hover}>
                  <h2 className={styles.projects_title}>Uso diario</h2>
                  <FaFileAlt className={styles.projects_icon}/>
                </div>
              </article>

            </div>
          </div>
        </section>
     );
}
 
export default Products;