import styles from "./App.module.css"

import Presentation from "./components/static/Presentation"
import About from "./components/static/About"
import Products from "./components/static/Products"
import Opinions from "./components/static/Opinions"
import Footer from "./components/static/Footer"
import Header from "./components/static/Header"


function App() {
  return (
    <div className={styles}>
      <Header/>
      <main>
        <Presentation/>
        <About/>
        <Products/>
        <Opinions/>
      </main>
      
      <Footer/>
    
    </div>
  );
}

export default App;
