import styles from "./Opinions.module.css";
import doctor from "./../../assets/doctor.jpg"
import doctora from "./../../assets/doctora.jpg"

const Opinions = () => {
    return ( 
        <section className={`${styles.about} ${styles.container}`}> 
          <h2 className={styles.subtitle}>Que dicen de nosotros</h2>
          
          <div className={styles.testimony_grid}>  

            <article className={styles.testimony_item}>
              <div className={styles.testimony_person}>
                <img src={doctor} alt="Persona del primer testimonio" className={styles.testimony_img} />
                <div className={styles.testimony_texts}>
                  <h3 className={styles.testimony_name}>Dr. Frecks</h3>
                  <p className={styles.testimony_verification}>Centro de salud Minsalud</p>
                </div>
              </div>
              <p className={styles.testimony_review}>
                Confiable rapido y de calidad, muy recomendable.
              </p>
            </article>
            
            <article className={styles.testimony_item}>
              <div className={styles.testimony_person}>
                <img src={doctora} alt="Persona del primer testimonio" className={styles.testimony_img} />
                <div className={styles.testimony_texts}>
                  <h3 className={styles.testimony_name}>Dra. Lista</h3>
                  <p className={styles.testimony_verification}>Clinica dental dentaldento</p>
                </div>
              </div>
              <p className={styles.testimony_review}>
                Buena atención y disponibilidad de productos inmediata, muy conforme.
              </p>
            </article>

          </div>

        </section>

     );
}
 
export default Opinions;