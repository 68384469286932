import styles from "./About.module.css";
import comfort from "./../../assets/comfort.svg"
import delivery from "./../../assets/delivery.svg"

const About = () => {
    return (
        <section className={`${styles.about} ${styles.container}`}>
          
          <div className={styles.about_texts}>
            <h2 className={styles.subtitle}>Sucursales</h2>
            <p className={styles.about_paragraph}>
              Tenemos sucursales de retiro de productos en las ciudades de Temuco, Valdivia y Osorno.
            </p>
            <p className={styles.about_paragraph}>
              ademas tenemos envíos a todo Chile vía Starken o Chile express.
            </p>
          </div>

          <figure className={styles.about_img}>
            <img src={delivery} alt="Delivery" className={styles.about_picture}/>
          </figure>

          <figure className={styles.about_img}>
            <img src={comfort} alt="Comodidad" className={styles.about_picture}/>
          </figure>


          <div className={styles.about_texts}>
            <h2 className={styles.subtitle}>Comodidad</h2>
            <p className={styles.about_paragraph}>
              Hoy lo importante es no exponerse, conoce nuestro catalogo de productos y compra 100% online.
            </p>
            <p className={styles.about_paragraph}>
              Tambien puedes optar por una atención personalida en nuestras redes sociales, Estamos en Facebook, Instagram y Whatsapp
            </p>
          </div>
        </section>
     );
}
 
export default About;