import styles from "./Wave.module.css";

const Wave = () => {
    return ( 
        <div className={styles.hero_wave}>
          <svg viewBox="0 0 500 150" preserveAspectRatio="none" className={styles.hero_wave_svg}>
            <path d="M0.00,49.98 C149.99,150.00 349.20,-49.98 500.00,49.98 L500.00,150.00 L0.00,150.00 Z" className={styles.hero_wave_path}>
            </path>
          </svg> 
        </div>
     );
}
 
export default Wave;